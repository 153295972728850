import {Controller, useForm} from 'react-hook-form';
import {Keyboard} from 'react-native';
import {TextInput} from 'react-native-paper';

export default function NewTodoForm({isCreating, onCreate}) {
  const {control, handleSubmit, setValue} = useForm({
    defaultValues: {name: ''},
  });

  async function handleCreate({name}) {
    console.log(1);
    try {
      if (name !== '') {
        console.log(2);
        await onCreate(name);
        setValue('name', '');
      }
      console.log(3);
      Keyboard.dismiss();
    } catch (e) {
      console.log(4, e);
      // no-op so browser sees promise rejection handled
    }
  }

  return (
    <>
      <Controller
        control={control}
        name="name"
        render={({field: {onChange, value}}) => (
          <TextInput
            testID="new-todo-name"
            label="New todo name"
            accessibilityLabel="New todo name"
            value={value}
            onChangeText={onChange}
            onSubmitEditing={handleSubmit(handleCreate)}
            autoCapitalize="sentences"
            autoCorrect
            right={isCreating && <TextInput.Icon icon="clock-outline" />}
          />
        )}
      />
    </>
  );
}
